import React from "react";
import "./about.css";
import AboutMe from "../../assets/me-about.png";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";
import {RiSpaceShipLine} from 'react-icons/ri'

const About = () => {
  return (
    <section id="about" className='section-style'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={AboutMe} alt="about image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>9+ years</small>
            </article>

            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Clients</h5>
              <small>20+</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>15+</small>
            </article>

            <article className="about__card">
              <RiSpaceShipLine className="about__icon" />
              <h5>Startups</h5>
              <small>3</small>
            </article>
          </div>

          <p>
          Front-end developer with 10 years of experience using technologies to build responsive web applications. 
            Over five years of experience working with startup teams and C-suite executives. 
            Diverse skills help me communicate effectively with my colleagues to achieve the best result and deliver tasks on time. 
            I recognize myself as an ambitious, positive, and reliable person who works to serve a team. Due to diversity of skills, 
            strong performance, and organizational impact promoted three times in 2 years.
          </p>

        </div>
      </div>
    </section>
  );
};

export default About;
