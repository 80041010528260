import React, { useState } from 'react'
import './portfolio.css'
import {projects, moreProjects} from './projectsData'


const Portfolio = () => {

  const [showMore, setShowMore] = useState(false)

  return (
    <section id='portfolio' className='section-style'>
      <h5>My Recent Web Development Projects</h5>
      <h2>Portfolio</h2>

      <div className='container portfolio__container'>
        {
        projects.map((item) => (
          <article key={item.id} className='portfolio__item'>
            <div className='portfolio__item-image'>
              <img src={item.image} alt='project image'/>
            </div>
            <div className='btn-style'>
            <h3>{item.title}</h3>
            {/* <a href={item.github} className='btn'>Code</a> */}
            <a href={item.demo} className='btn btn-primary' target='_blank'>Demo</a>
            </div>
            <h4>Technologies</h4>
            <ul className='tech__list'>
              {item.tech?.map(({techId, techImage, techName}) => (
                  <li key={techId}>
                    <div className='tech__list-icon'>
                      {techImage}
                    </div>
                    <p>{techName}</p>
                  </li>
              ))}
            </ul> 
          </article>
          ))}              

        
        {showMore &&
        moreProjects.map((item) => (
          <article key={item.id} className='portfolio__item'>
            <div className='portfolio__item-image'>
              <img src={item.image} alt='project image'/>
            </div>
            <div className='btn-style'>
            <h3>{item.title}</h3>
            {/* <a href={item.github} className='btn'>Code</a> */}
            <a href={item.demo} className='btn btn-primary' target='_blank'>Demo</a>
            </div>
            <h4>Technologies</h4>
            <ul className='tech__list'>
              {item.tech?.map(({techId, techImage, techName}) => (
                  <li key={techId}>
                    <div className='tech__list-icon'>
                      {techImage}
                    </div>
                    <p>{techName}</p>
                  </li>
              ))}
            </ul> 
          </article>    
        ))
        }
        </div>
        <div className='btn-more'>
            <button 
            className='btn-show'
            onClick={() => setShowMore(!showMore)}
            >
              {showMore ? 'Show Less' : 'Show More'}
            </button>
        </div>    
    </section>
  )
}

export default Portfolio