import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import swal from 'sweetalert';
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {BsWhatsapp} from 'react-icons/bs'
import {BiMobileAlt} from 'react-icons/bi'


const Contact = () => {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_t4edk85', 'template_jsofsfa', form.current, process.env.REACT_APP_EMAIL_KEY)
    
    e.target.reset();

    swal('Your message has been sent successfully!')
  };

  return (
    <section id='contact' className='section-style'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className='container contact__container'>
        <div className='contact__options'>
          <article className='contact__option'>
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>mrsharifi.cs@gmail.com</h5>
            <a href='mailto:mrsharifi.cs@gmail.com' target='_blank'>Send a mail</a>
          </article>
          <article className='contact__option'>
            <BsWhatsapp className='contact__option-icon'/>
            <h4>WhatsApp</h4>
            <h5>+989304599071</h5>
            <a href='https://wa.me/989304599071' target='_blank'>Send a message</a>
          </article>
          <article className='contact__option'>
            <BiMobileAlt className='contact__option-icon'/>
            <h4>Phone</h4>
            <h5>+905013637236</h5>
            <a href='sms:+905013637236' target='_blank'>send a text</a>
          </article>
        </div>

        {/*Form*/}
        <form ref={form} onSubmit={sendEmail}>
          <input type='text' name='name' placeholder='Full Name' required />
          <input type='email' name='email' placeholder='Email' required />
          <input type='tel' name='phone' placeholder='Phone Number' required />
          <textarea name='message' rows='7' placeholder='Your Message' required ></textarea>
          <button type='submit' className='btn btn-primary btn-form' >Send</button>
        </form>
      </div>
    </section>
  )
}

export default Contact