import IMG1 from '../../assets/portfolio1.png'
import IMG2 from '../../assets/portfolio2.png'
import IMG3 from '../../assets/portfolio3.png'
import IMG4 from '../../assets/portfolio4.png'
import IMG5 from '../../assets/portfolio5.png'
import IMG6 from '../../assets/portfolio6.png'
import IMG7 from '../../assets/portfolio7.png'
import IMG8 from '../../assets/portfolio8.png'
import IMG9 from '../../assets/portfolio9.png'
import IMG10 from '../../assets/portfolio10.png'
import IMG11 from '../../assets/portfolio11.png'
import IMG12 from '../../assets/portfolio12.png'
import IMG13 from '../../assets/portfolio13.png'
import IMG14 from '../../assets/portfolio14.jpg'


import {TbApi} from 'react-icons/tb'
import {DiJavascript1} from 'react-icons/di'
import {GrReactjs} from 'react-icons/gr'
import {SiTailwindcss} from 'react-icons/si'
import {IoLogoFirebase} from 'react-icons/io5'
import {DiCss3} from 'react-icons/di'
import {TbBrandNextjs} from 'react-icons/tb'
import {SiMaterialui} from 'react-icons/si'
import {SiChakraui} from 'react-icons/si'
import {SiGraphql} from 'react-icons/si'
import {SiTypescript} from 'react-icons/si'
import {SiRedux} from 'react-icons/si'
import {SiStyledcomponents} from 'react-icons/si'
import {SiMongodb} from 'react-icons/si'

export const projects = [
    {
        id: 1,
        image: IMG1,
        title: 'Hapito UI',
        github: 'https://github.com',
        demo: 'https://hapito-demo-app.vercel.app/',
        tech: [
            {
                techId: 101,
                techName: 'React',
                techImage: <GrReactjs />,
            },
            {
                techId: 102,
                techName: 'Tailwind',
                techImage: <SiTailwindcss />,
            },
        ],
    },
    {
        id: 2,
        image: IMG2,
        title: 'Opaco',
        github: 'https://github.com',
        demo: 'https://opaco-app.vercel.app/',
        tech: [
            {
                techId: 101,
                techName: 'React',
                techImage: <GrReactjs />,
            },
            {
                techId: 102,
                techName: 'Tailwind',
                techImage: <SiTailwindcss />,
            },
        ],
    },
    {
        id: 3,
        image: IMG5,
        title: 'Lyrika',
        github: 'https://github.com',
        demo: 'https://lyrika-app.vercel.app/',
        tech: [
            {
                techId: 101,
                techName: 'React',
                techImage: <GrReactjs />,
            },
            {
                techId: 102,
                techName: 'Tailwind',
                techImage: <SiTailwindcss />,
            },
            {
                techId: 109,
                techName: 'Rapid API',
                techImage: <TbApi />,
            },
            {
                techId: 112,
                techName: 'Redux',
                techImage: <SiRedux />,
            },
        ],
    },
    {
        id: 4,
        image: IMG4,
        title: 'Homeway',
        github: 'https://github.com',
        demo: 'https://homeway-realestate.vercel.app/',
        tech: [
            {
                techId: 107,
                techName: 'Next JS',
                techImage: <TbBrandNextjs />,
            },
            {
                techId: 102,
                techName: 'Tailwind',
                techImage: <SiTailwindcss />,
            },
            {
                techId: 105,
                techName: 'Chakra UI',
                techImage: <SiChakraui />,
            }, 
            {
                techId: 109,
                techName: 'Rapid API',
                techImage: <TbApi />,
            },
        ],
    }
]

export const moreProjects = [
    {
        id: 5,
        image: IMG3,
        title: 'Shiva',
        github: 'https://github.com',
        demo: 'https://shiva-portfolio-iota.vercel.app/',
        tech: [
            {
                techId: 101,
                techName: 'React',
                techImage: <GrReactjs />,
            },
            {
                techId: 102,
                techName: 'Tailwind',
                techImage: <SiTailwindcss />,
            },
        ],
    },
    {
        id: 6,
        image: IMG6,
        title: 'Favfilix',
        github: 'https://github.com',
        demo: 'https://favfilix-app.vercel.app/',
        tech: [
            {
                techId: 101,
                techName: 'React',
                techImage: <GrReactjs />,
            },
            {
                techId: 102,
                techName: 'Tailwind',
                techImage: <SiTailwindcss />,
            },
            {
                techId: 108,
                techName: 'Firebase',
                techImage: <IoLogoFirebase />,
            },
            {
                techId: 109,
                techName: 'TMDB API',
                techImage: <TbApi />,
            },
        ],
    },
    {
        id: 7,
        image: IMG7,
        title: 'MIM Media',
        github: 'https://github.com',
        demo: 'https://mim-media-app.vercel.app/',
        tech: [
            {
                techId: 101,
                techName: 'React',
                techImage: <GrReactjs />,
            },
            {
                techId: 106,
                techName: 'Material UI',
                techImage: <SiMaterialui />,
            },
            {
                techId: 109,
                techName: 'Rapid API',
                techImage: <TbApi />,
            },  
        ],
    },
    {
        id: 8,
        image: IMG8,
        title: 'Furnishop',
        github: 'https://github.com',
        demo: 'https://furnishop-app.vercel.app/',
        tech: [
            {
                techId: 101,
                techName: 'React',
                techImage: <GrReactjs />,
            },
            {
                techId: 102,
                techName: 'Tailwind',
                techImage: <SiTailwindcss />,
            },
        ],
    },
    {
        id: 9,
        image: IMG9,
        title: 'Hito Bank',
        github: 'https://github.com',
        demo: 'https://hitobank-app.vercel.app/',
        tech: [
            {
                techId: 101,
                techName: 'React',
                techImage: <GrReactjs />,
            },
            {
                techId: 102,
                techName: 'Tailwind',
                techImage: <SiTailwindcss />,
            },
        ],
    },
    {
        id: 10,
        image: IMG10,
        title: 'Recipe Finder',
        github: 'https://github.com',
        demo: 'https://recipe-finder-app-mu.vercel.app/',
        tech: [
            {
                techId: 101,
                techName: 'React',
                techImage: <GrReactjs />,
            },
            {
                techId: 113,
                techName: 'Styled Component',
                techImage: <SiStyledcomponents />,
            },
            {
                techId: 109,
                techName: 'Spoonacular API',
                techImage: <TbApi />,
            },
        ],
    },
    {
        id: 11,
        image: IMG11,
        title: 'Atromis',
        github: 'https://github.com',
        demo: 'https://cloud-app-lake.vercel.app/',
        tech: [
            {
                techId: 101,
                techName: 'React',
                techImage: <GrReactjs />,
            },
            {
                techId: 102,
                techName: 'Tailwind',
                techImage: <SiTailwindcss />,
            },
        ],
    },
    {
        id: 12,
        image: IMG12,
        title: 'TRAX',
        github: 'https://github.com',
        demo: 'https://space-travel-app.vercel.app/',
        tech: [
            {
                techId: 101,
                techName: 'React',
                techImage: <GrReactjs />,
            },
            {
                techId: 104,
                techName: 'CSS',
                techImage: <DiCss3 />,
            },
        ],
    },
    {
        id: 13,
        image: IMG14,
        title: 'Carnito',
        github: 'https://github.com',
        demo: 'https://carnito.vercel.app/',
        tech: [
            {
                techId: 107,
                techName: 'Next JS',
                techImage: <TbBrandNextjs />,
            },
             {
                techId: 111,
                techName: 'TypeScript',
                techImage: <SiTypescript />,
            },
            {
                techId: 102,
                techName: 'Tailwind',
                techImage: <SiTailwindcss />,
            },
            {
                techId: 109,
                techName: 'Rapid API',
                techImage: <TbApi />,
            }, 
        ],
    }
]


// tech: [
//     {
//         techId: 101,
//         techName: 'React',
//         techImage: <GrReactjs />,
//     },
//     {
//         techId: 102,
//         techName: 'Tailwind',
//         techImage: <SiTailwindcss />,
//     },
//     {
//         techId: 103,
//         techName: 'JavaScript',
//         techImage: <DiJavascript1 />,
//     },
//     {
//         techId: 104,
//         techName: 'CSS',
//         techImage: <DiCss3 />,
//     },
//     {
//         techId: 105,
//         techName: 'Chakra UI',
//         techImage: <SiChakraui />,
//     },
//     {
//         techId: 106,
//         techName: 'Material UI',
//         techImage: <SiMaterialui />,
//     },
//     {
//         techId: 107,
//         techName: 'Next JS',
//         techImage: <TbBrandNextjs />,
//     }, 
//     {
//         techId: 108,
//         techName: 'Firebase',
//         techImage: <IoLogoFirebase />,
//     },
//     {
//         techId: 109,
//         techName: 'Rapid API',
//         techImage: <TbApi />,
//     },
//     {
//         techId: 110,
//         techName: 'Graph QL',
//         techImage: <SiGraphql />,
//     },
//     {
//         techId: 111,
//         techName: 'TypeScript',
//         techImage: <SiTypescript />,
//     },
    // {
    //     techId: 112,
    //     techName: 'Redux',
    //     techImage: <SiRedux />,
    // },
//     {
//     techId: 113,
//     techName: 'Styled Component',
//     techImage: <SiStyledcomponents />,
//      },
//     {
//     techId: 114,
//     techName: 'Mongo DB',
//     techImage: <SiMongodb />,
//      },  
// ]
