import React from 'react'
import './footer.css'
import Sharif from '../../assets/sharif.png'
import {FiGithub} from 'react-icons/fi'
import {FaLinkedinIn} from 'react-icons/fa'

const Footer = () => {
  return (
    <footer>
      <div className='left__side'>
        <a href='#' className='footer__logo'>
          <img src={Sharif} alt='sharif' />
        </a>
        <div className='footer__copyright'>
          <small>&copy; Mohammadreza Sharifi. All rights reserved.</small>
        </div>
      </div>

      <div className='footer__socials'>
        {/* <a href='https://github.com/mrsharifii' target='_blank'><FiGithub /></a> */}
        <a href='https://www.linkedin.com/in/mrsharifi/' target='_blank'><FaLinkedinIn /></a>
      </div>

      
    </footer>
  )
}

export default Footer
