import React from "react";
import "./testimonial.css";
import { BsLinkedin } from "react-icons/bs";
import { review } from "./reviewData";

import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

const Testimonial = () => {
  return (
    <section id="testimonial" className='section-style'>
      <h5>Review from colleagues</h5>
      <h2>Recommendations</h2>

      <Swiper className="container testimonials__container"
        modules={[Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        
        {review.map(({id, name, image, link, text}) => (
          <SwiperSlide key={id} className="testimonial">
            <div className="avatar">
            <img src={image} />
            <h5 className="avatar_name">{name}</h5>
            <a href={link} target='_blank'>
              <BsLinkedin />
            </a>
            <small className="review">
              {text} 
            </small>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Testimonial;
