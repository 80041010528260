import AV1 from "../../assets/avatar01.jpg";
import AV2 from "../../assets/avatar02.jpg";
import AV3 from "../../assets/avatar03.jpg";
import AV4 from "../../assets/avatar04.jpg";
import AV5 from "../../assets/avatar05.jpg";

export const review = [
    {
        id: 1,
        name: 'Hossein Moazzen',
        image: AV1,
        link: 'https://www.linkedin.com/in/moazzen/',
        text: `Mohammad Reza is a hardworking person. He will ask the right
        questions and gets the job done ASAP, no reminders or monitoring
        required, he's just that automated, answering and committed. Totally recommended.`
    },
    {
        id: 2,
        name: 'Ali Badiei',
        image: AV2,
        link: 'https://www.linkedin.com/in/ali-badiei/',
        text: `Mohammad is one of the best colleagues I've had. He puts all the
        effort needed to get things done. He sees detail faster than any
        one I know and gives you the best practical feedback in the best
        time you can use it. good luck man!`
    },
    {
        id: 3,
        name: 'Shiva Ashraf Joulaei',
        image: AV3,
        link: 'https://www.linkedin.com/in/shivaashrafjoulaie/',
        text: `Mohammad Reza is one of the best front-end developers I've ever worked with. He is genial, upbeat, and supremely well-read. He will continue to be successful in his career path and a tremendous asset to any team. If you have a chance to work with him, in any capacity, don't pass it up!`
    },
    {
        id: 4,
        name: 'Nasser Ghanemzadeh',
        image: AV4,
        link: 'https://www.linkedin.com/in/ghanemzadeh/',
        text: `It's more than three years that I know Mohammadreza; He has been
        genuine, has a great sense of humor, and more importantly focused.
        He possesses a strong work ethic, a high level of integrity, and a
        good-natured attitude that makes him an asset to any organization.
        When it comes to his work he is very dedicated, organized and
        detail-oriented.`
    },
]
